import axiosClient from './axiosClient';

const cartProductApi = {
    getCartProducts: () => {
        const url = '/api/myCartProducts';
        return axiosClient.get(url);
    },

    addCartProducts: (data) => {
        const url = '/api/myCartProducts';
        return axiosClient.post(url, data, { params: data });
    },

    updateCartProducts: (data) => {
        const { id } = data;
        const url = `/api/myCartProducts/${id}`;
        return axiosClient.put(url, data);
    },

    deleteCartProducts: (id) => {
        const url = `/api/myCartProducts/${id}`;
        return axiosClient.delete(url);
    },

    deleteFavCartProducts: (data) =>{
        const {productId,userId} = data
        const url = `/api/myCartProducts/${productId}/user/${userId}`;
        return axiosClient.delete(url);
    }
};

export default cartProductApi;
