import React from 'react';
import {emptyFavProd} from 'assets/images';
import {Controller} from 'react-hook-form';
import {FormControl} from '@mui/material';
import ProductCard from 'components/ProductCard/ProductCard';
import DeleteFavProductModal from './subViews/DeleteFavProductModal';
import CategorySelect from 'components/CategorySelect/CategorySelect';
import CustomSelect from 'components/CustomSelect/CustomSelect';

const MyLikeProductMainView = (props) => {
    const {
        intl,
        dispatch,
        userLikeList,
        navigateTo,
        multipleSelect,
        setMultipleSelect,
        onOpenDeleteModal,
        closeDeleteModal,
        selectedProdId,
        isOpenDeleteModal,
        displayList,
        handleDeleteAddress,
        removeCategory,
        selectedCategoryList,
        control
    } = props;
    const categoryList = userLikeList.categoryList;

    const categorySelectProps = {
        removeCategory,
        multiple: true,
        categories: categoryList,
        placeholder: intl.messages.chooseCategory,
        label: intl.messages.category
    };

    const statusOptions = [
        {id: intl.messages.newUpdate, value: intl.messages.newUpdate},
        {id: intl.messages.newProduct, value: intl.messages.newProduct},
        {id: intl.messages.oldProduct, value: intl.messages.oldProduct}
    ];
    const priceOptions = [
        {id: intl.messages.fromLowToHigh, value: intl.messages.fromLowToHigh},
        {id: intl.messages.fromHighToLow, value: intl.messages.fromHighToLow}
    ];
    return (
        <>
            <DeleteFavProductModal
                isOpenDeleteModal={isOpenDeleteModal}
                closeDeleteModal={closeDeleteModal}
                handleDeleteAddress={handleDeleteAddress}
                selectedProdId={selectedProdId}
                intl={intl}
            />
            <div className='favourite-product'>
                <div className='favourite-product-title'>
                    <h3 className='title'>{intl.messages.favoriteProduct}</h3>
                </div>
                <div className='fav-quantity'>
                    <span>
                        {userLikeList.likeProductList?.collection?.length} {intl.messages.product}
                    </span>
                </div>
                <div className='favourite-sort'>
                    {intl.messages.sortBy}:
                    <div className='d-flex align-items-center gap-3' style={{flex: 1}}>
                        <div className='favourite-sort__filter status-filter'>
                            <Controller
                                control={control}
                                name='status'
                                render={({field}) => (
                                    <FormControl fullWidth>
                                        <CustomSelect
                                            {...field}
                                            options={statusOptions}
                                            placeholder={intl.messages.status}
                                            label={intl.messages.status}
                                        />
                                    </FormControl>
                                )}
                            />
                        </div>
                        <div className='favourite-sort__filter category-filter'>
                            <Controller
                                control={control}
                                name='category'
                                render={({field}) => (
                                    <FormControl fullWidth>
                                        <CategorySelect {...field} {...categorySelectProps} />
                                    </FormControl>
                                )}
                            />
                        </div>
                        <div className='favourite-sort__filter price-filter'>
                            <Controller
                                control={control}
                                name='price'
                                render={({field}) => (
                                    <FormControl fullWidth>
                                        <CustomSelect
                                            {...field}
                                            options={priceOptions}
                                            label={intl.messages.price}
                                            noPlaceholder
                                        />
                                    </FormControl>
                                )}
                            />
                        </div>
                    </div>
                </div>
                {displayList.length > 0 ? (
                    <div className='favourite-product-container'>
                        {displayList?.map((product) => (
                            <ProductCard
                                item={product}
                                key={product.id}
                                navigateTo={navigateTo}
                                multipleSelect={multipleSelect}
                                setMultipleSelect={setMultipleSelect}
                                onOpenDeleteModal={onOpenDeleteModal}
                                intl={intl}
                            />
                        ))}
                    </div>
                ) : (
                    <div className='empty-fav-prod'>
                        <img src={emptyFavProd} alt='empty-fav-product' />
                        <div className='empty-fav-prod-text'>{intl.messages.emptyFavoriteProducts}</div>
                    </div>
                )}
            </div>
        </>
    );
};

export default MyLikeProductMainView;
