import {pagePath} from 'configuration/routeConfig';
import {eventsKey} from 'configuration/storageKey';
import {addCartProducts} from 'features/Cart/cartSlice';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router';
import Events from 'utils/Events';
import {getBase64} from 'utils/getBase64';
import {Toast} from 'utils/toast';
import {
    getProductReviewByIdAndOrderProductId,
    postProductReview,
    postProductReviewAttachment,
    uploadProductReviewAttachment
} from '../MyPurchase/myPurchaseSlice';
import propsProvider from './MyDetailPurchasePropsProvider';
import {getOrderById, proceedOrderStep, proceedOrderSubStep} from './MyDetailPurchaseSlice';
import MyDetailPurchaseMainView from './template/MyDetailPurchaseMainView';
import {orderActions} from '../MyPurchase/MyPurchaseContainer';

const MyDetailPurchaseContainer = (props) => {
    const {dispatch, adminOrderDetail, history} = props;
    const intl = useIntl();
    const {orderId} = useParams();
    const [currentStep, setCurrentStep] = useState('');
    const [countDown, setCountDown] = useState(null);
    const [isOpenReasonModal, setOpenReasonModal] = useState(false);
    const [reasonValue, setReasonValue] = useState('');
    const [isOpenReviewModal, setOpenReviewModal] = useState(false);
    const [reviewValues, setReviewValues] = useState([]);
    const [isViewDetail, setIsViewDetail] = useState(false);

    const navigateTo = (path) => {
        history.push(path);
    };

    const closeReasonModal = () => {
        setOpenReasonModal(false);
    };

    const openReasonModal = () => {
        setOpenReasonModal(true);
    };

    const handleUploadImages = async (e, productIndex) => {
        const files = e.target.files;
        const previewImageUrls = [];
        let isOverSize = false;

        for (const file of files) {
            const {size} = file;

            if (size > 5 * 1024 * 1024) {
                isOverSize = true;
                return;
            }
            const result = await getBase64(file);
            previewImageUrls.push({url: result, file});
        }

        if (isOverSize) {
            Toast(false, intl.messages.overSize);
        } else {
            const newReviewValues = [...reviewValues].map((el, idx) => {
                if (idx === productIndex) {
                    const newPreviewImageUrls = [...el.detailImages, ...previewImageUrls];
                    el['detailImages'] = newPreviewImageUrls;
                    return el;
                }
                return el;
            });
            setReviewValues(newReviewValues);
        }
    };

    const closeReviewModal = () => {
        setReviewValues([]);
        setOpenReviewModal(false);
        setIsViewDetail(false);
    };

    const openReviewModal = (productList) => {
        if (productList) {
            const initialState = productList.map((product) => ({
                ...product,
                comment: '',
                rating: 0,
                ratingHover: -1,
                detailImages: [],
                is_name_display: false
            }));
            setReviewValues(initialState);
        }
        setOpenReviewModal(true);
    };

    const openReviewDetailModal = async (productList) => {
        if (productList) {
            const initialState = [];

            for (const product of productList) {
                const {productId, orderProductId} = product;
                const res = await dispatch(getProductReviewByIdAndOrderProductId({productId, orderProductId})).unwrap();
                const {success} = res;
                if (success) {
                    const {data} = res;
                    if (data?.total > 0) {
                        const reviewData = data?.collection[0];
                        const {user_id, rating, review_text, extend_product_review_attachment} = reviewData;
                        initialState.push({
                            ...product,
                            comment: review_text,
                            rating: rating,
                            detailImages: extend_product_review_attachment,
                            is_name_display: !!user_id
                        });
                    }
                }
            }
            setReviewValues(initialState);
        }
        setIsViewDetail(true);
        setOpenReviewModal(true);
    };

    const handleReviewOrder = async () => {
        if (reviewValues) {
            for (const product of reviewValues) {
                const {orderProductId, productId, userId, comment, rating, is_name_display, detailImages} = product;
                const payload = {
                    order_product_id: orderProductId,
                    product_id: productId,
                    user_id: is_name_display ? userId : '',
                    review_text: comment,
                    rating: parseInt(rating)
                };
                const reviewResponse = await dispatch(postProductReview(payload)).unwrap();
                const {success} = reviewResponse;
                if (success) {
                    const {data} = reviewResponse;
                    const reviewId = data?.id;

                    if (detailImages && detailImages.length > 0) {
                        for (const idx in detailImages) {
                            const res = await dispatch(
                                postProductReviewAttachment({product_review_id: reviewId})
                            ).unwrap();
                            const {success} = res;
                            if (success) {
                                const {data} = res;

                                const fileFormData = new FormData();
                                const file = detailImages[idx].file;
                                if (file) {
                                    fileFormData.append('file', file);
                                    await dispatch(uploadProductReviewAttachment({id: data?.id, file: fileFormData}));
                                }
                            }
                        }
                    }
                }
            }
            await dispatch(proceedOrderStep({id: orderId, nextStepCode: 'Done', isReviewed: true}));
            Toast(true, 'Đánh giá thành công');
            fetchOrderById();
        }
        closeReviewModal();
    };

    const handleBuyAgain = async (products) => {
        try {
            if (products) {
                for (const product of products) {
                    const {productId, subTypeId} = product;
                    await dispatch(addCartProducts({productId, quantity: 1, type: 'cart', subTypeId}));
                }
                Events.emit(eventsKey.updateCart);
                navigateTo(pagePath.cartUrl);
                Toast(true, 'Mua lại thành công');
            }
        } catch (err) {
            Toast(false, 'Mua lại thất bại');
        }
    };

    const handleOrderStep = async (type = '', payload) => {
        const {products} = payload;

        try {
            switch (type) {
                case orderActions.confirmReceived:
                    await dispatch(proceedOrderStep({id: orderId, nextStepCode: 'Done'}));
                    fetchOrderById();
                    break;
                case orderActions.refund:
                    // await dispatch(proceedOrderStep({id: orderId, nextStepCode: 'Refund'}));
                    // fetchOrderById();
                    navigateTo('/user/refund/' + orderId);
                    break;
                case orderActions.review:
                    return openReviewModal(products);
                case orderActions.reviewDetail:
                    return openReviewDetailModal(products);
                case orderActions.buyAgain:
                    return handleBuyAgain(products);
                case orderActions.cancel:
                    return openReasonModal();
                default:
                    break;
            }
            Toast(true, intl.messages.actionSuccess);
        } catch (err) {
            Toast(false, intl.messages.actionFail);
        }
    };

    const handleCancelOrder = async (reason) => {
        const params = {
            id: orderId,
            nextStepCode: 'Cancel',
            cancelReason: reason || reasonValue
        };
        const res = await dispatch(proceedOrderStep(params)).unwrap();
        await dispatch(proceedOrderSubStep({id: orderId, nextStepCode: 'USER_CANCEL'}));
        const {success} = res;
        if (success) {
            Toast(success, 'Đã hủy đơn hàng');
            fetchOrderById();
        } else {
            Toast(success, 'Hủy đơn không thành công!');
        }
    };

    const fetchOrderById = async () => {
        if (orderId) {
            const res = await dispatch(getOrderById(orderId)).unwrap();
            const {success} = res;
            if (success) {
                const {data} = res;
                setCurrentStep({
                    name: data?.order_information?.extend_step?.name,
                    code: data?.order_information?.extend_step?.code
                });
                const timeLeft =
                    moment(data?.order_information?.order_created_at).add(3, 'd').valueOf() - moment().valueOf();
                setCountDown(timeLeft);
            }
        }
    };

    useEffect(() => {
        setReasonValue('');
    }, [isOpenReasonModal]);

    useEffect(() => {
        fetchOrderById();
    }, [orderId]);

    useEffect(() => {
        if (currentStep && currentStep?.name === 'Chờ xác nhận') {
            if (countDown > 0) {
                setTimeout(() => {
                    setCountDown((prev) => prev - 1000);
                }, 1000);
            } else {
                handleCancelOrder('Quá thời hạn xác nhận đơn hàng');
            }
        }
        return () => {
            clearTimeout();
        };
    }, [countDown, currentStep]);

    const myDetailPurchaseContainerProps = {
        ...props,
        dispatch,
        intl,
        adminOrderDetail,
        currentStep,
        handleOrderStep,
        countDown,
        isOpenReasonModal,
        closeReasonModal,
        handleCancelOrder,
        reasonValue,
        setReasonValue,
        isOpenReviewModal,
        closeReviewModal,
        handleReviewOrder,
        handleUploadImages,
        reviewValues,
        setReviewValues,
        isViewDetail
    };

    return <MyDetailPurchaseMainView {...propsProvider(myDetailPurchaseContainerProps)} />;
};

export default MyDetailPurchaseContainer;
