import {Rating} from '@mui/material';
import {Heart, likeOutline, Share} from 'assets/images';
import React from 'react';
import {FormattedNumber} from 'react-intl';
import SubProductDetails from './SubProductIDetails';
import {Pagination, Navigation} from 'swiper';
import {SwiperSlide, Swiper} from 'swiper/react';

const ProductOverview = (props) => {
    const {
        productInfo,
        productImgs,
        intl,
        quantityDemand,
        isOpenPopup,
        imgUrls,
        index,
        setIndex,
        addToCartHandler,
        openShareModal,
        setQuantityDemand,
        mainGroupTypeState,
        displayProductType,
        handleProductTypeChange,
        toggleLightbox,
        favProdId,
        handleDeleteAddress,
        auth,
        checkFavProd,
        totalReviews,
        avgRating
    } = props;
    const {id, productOptionId} = productInfo;
    const userId = auth.userLoginInfo?.id;
    const {quantity: quantitySupply, price, origin_price} = mainGroupTypeState;
    const subProductDetailsProps = {
        displayProductType,
        isOpen: isOpenPopup,
        setQuantityDemand,
        quantityDemand,
        quantitySupply,
        intl,
        handleProductTypeChange
    };
    return (
        productInfo && (
            <div className='row product-row d-flex justify-content-between gap-3'>
                <div className='col-lg-5 product-image d-flex align-tems-center gap-3'>
                    <Swiper
                        slidesPerView={5}
                        slidesPerGroup={5}
                        spaceBetween={12}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        direction={'vertical'}
                        className='d-none d-lg-flex product-image-list'>
                        {productImgs.length > 0 &&
                            productImgs.map((img, idx) => (
                                <SwiperSlide
                                    key={idx}
                                    className={`img-item d-flex align-items-center justify-content-center ${
                                        idx === index ? 'item-active' : ''
                                    }`}
                                    onMouseEnter={() => setIndex(idx)}>
                                    <img src={img.url} alt='product-img' />
                                </SwiperSlide>
                            ))}
                    </Swiper>
                    <div className='product-img product-img-display'>
                        <img src={imgUrls[index]} alt='' onClick={toggleLightbox} />
                    </div>
                </div>
                <div className='col product-data'>
                    <div className='product-data-content'>
                        <div className='product-data-content__header d-flex justify-content-between align-items-center'>
                            <div>
                                <span className='product__brand'>{intl.messages.brand}:</span>
                                <span className='product__brand-name'>Hải Đăng group</span>
                            </div>
                            <div className='product-data-content__header-right d-flex'>
                                {/* {favProdId.includes(id) || checkFavProd ? (
                                    <div
                                        className='sub-content-header'
                                        onClick={() => handleDeleteAddress({id, userId, cartType: 'wishlist'})}>
                                        <Heart />
                                        <span className='product__brand'> {intl.messages.alrFavorite} </span>
                                    </div>
                                ) : (
                                    <div
                                        className='sub-content-header'
                                        onClick={() => {
                                            addToCartHandler({
                                                id,
                                                quantityDemand,
                                                type: 'add',
                                                cartType: 'wishlist',
                                                productOptionId
                                            });
                                        }}>
                                        <img src={likeOutline} alt='like' />
                                        <span className='product__brand'> {intl.messages.favorite} </span>
                                    </div>
                                )} */}

                                <div className='sub-content-header' onClick={openShareModal}>
                                    <img src={Share} alt='share' />
                                    <span className='product__brand'> {intl.messages.share} </span>
                                </div>
                            </div>
                        </div>
                        <div className='product-data-content__container'>
                            <h3>{productInfo.name}</h3>
                            <div className='product-data-content__container-rating d-flex align-items-center'>
                                <Rating name='read-only' value={avgRating} size='small' readOnly />
                                <span className='rating-amount'>{avgRating}</span>
                                <span className='rating-amount'>
                                    {totalReviews} {intl.messages.rating}{' '}
                                </span>
                                <span className='sold-amount'>
                                    {productInfo.sold} {intl.messages.sold}{' '}
                                </span>
                            </div>
                            <div className='product-data-content__container-price d-flex align-items-center'>
                                <span className='mt-3 fw-bolder'>₫</span>
                                <div className='price-container'>
                                    <FormattedNumber currency='VND' value={price} />
                                </div>
                                {price !== origin_price && (
                                    <>
                                        <div className='origin-price-container d-flex align-items-center'>
                                            <span>₫</span>
                                            <FormattedNumber currency='VND' value={origin_price} />
                                        </div>
                                        <div className='sale-off'>
                                            <span className='sale-off-amount d-flex align-items-center'>
                                                {origin_price === 0
                                                    ? 0
                                                    : -Math.floor(((origin_price - price) / origin_price) * 100)}
                                                %
                                            </span>
                                        </div>
                                    </>
                                )}
                            </div>
                            {displayProductType.length > 0 && <SubProductDetails {...subProductDetailsProps} />}
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default ProductOverview;
