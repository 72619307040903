import React from 'react';
import CategoryItem from './CategoryItem';
import { pagePath } from 'configuration/routeConfig';
import SwiperList from 'components/SwiperList/SwiperList';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import { paramsKey } from 'configuration/storageKey';
import Config from 'configuration';

const Category = (props) => {
    const { intl, navigateTo, categoryList = [] } = props;
    const productCategoryList = categoryList.map((item) => {
        const category = {
            label: item.name,
            href: pagePath.plpUrl + `?${paramsKey.categoryNameId}=${item.name}.${item.id}`,
            icon: item.image ? `${Config.apiConfig.endPoint}${item.image}` : '',
            id: item.id
        };
        return <CategoryItem item={category} key={item.id} navigateTo={navigateTo} />;
    });

    const breakpoints = {
        1200: {
            slidesPerView: 8,
            slidesPerGroup: 8,
            grid: { rows: 2, fill: 'row' }
        },
        992: {
            slidesPerView: 5,
            slidesPerGroup: 5,
            grid: { rows: 2, fill: 'row' }
        },
        576: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            grid: { rows: 2, fill: 'row' }
        }
    };

    return (
        <nav className='category'>
            <SectionTitle>{intl.messages.productCategoryLabel} 🌟</SectionTitle>

            <ul className='category-menu w-100 m-0 p-0'>
                <SwiperList
                    list={productCategoryList}
                    slidesPerView={3}
                    slidesPerGroup={3}
                    rows={2}
                    spaceBetween={8}
                    breakpoints={breakpoints}
                />
            </ul>
        </nav>
    );
};

export default Category;
