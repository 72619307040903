import {FacebookShare} from 'assets/images';
import {FacebookShareButton, TwitterShareButton, TwitterIcon} from 'react-share';
import {calcTotalPage} from 'utils/calcTotalPage';

export default function propsProvider(props) {
    const {
        intl,
        navigateTo,
        productInfo,
        quantityDemand,
        productImgs,
        reviewPage,
        setReviewPage,
        allReviewList,
        productReviewsDetails,
        filterCategoryList,
        setFilterCategoryList,
        filterByStarList,
        setFilterByStarList,
        productDetails,
        currentRecommendList,
        setCurrentRecommendList,
        productId,
        showBuyModalPopup,
        showAddModalPopup,
        isBuyModal,
        closeModalPopup,
        isOpenPopup,
        addToCartHandler,
        index,
        setIndex,
        isOpenShareModal,
        closeShareModal,
        openShareModal,
        setQuantityDemand,
        mainGroupTypeState,
        displayProductType,
        handleProductTypeChange,
        toggleLightbox,
        isOpenLightbox,
        favProdId,
        handleDeleteAddress,
        auth,
        checkFavProd
    } = props;

    const imgUrls = productImgs.map((img) => img.url);

    const calcAvgRating = (reviewList, count) => {
        let sum = Array.isArray(reviewList) && reviewList.reduce((total, item) => total + item.rating, 0);
        return Math.round(sum / count);
    };
    const getDataValue = (data) => (data ? data : intl.messages.updating);

    const {description, product_code, features, warranty_period, warranty_policy, origin} = productInfo || {
        description: null,
        product_code: null,
        features: null,
        warranty_period: null,
        warranty_policy: null,
        origin: null
    };

    const productDetailItems = [
        {label: intl.messages.brand, value: 'Hải Đăng group'},
        {label: intl.messages.productCode, value: getDataValue(product_code)},
        {label: intl.messages.origin, value: getDataValue(origin)},
        {label: intl.messages.warrantyPeriod, value: getDataValue(warranty_period)}
    ];

    if (features && features.length > 0) {
        if (features[0] === '{') {
            const {featureList} = JSON.parse(features);
            featureList.forEach((feature, idx) => {
                productDetailItems.splice(1 + idx, 0, {label: feature.name, value: getDataValue(feature.value)});
            });
        } else {
            productDetailItems.splice(1, 0, {label: intl.messages.features, value: getDataValue(features)});
        }
    }

    const readMoreSectionItems = [
        {title: intl.messages.productDescription, content: description ? description : intl.messages.noDescription}
    ];

    const socialLinks = [
        {
            icon: (
                <FacebookShareButton
                    url={window.location.href}
                    quote={'Hãy xem sản phẩm mới nhé'}
                    hashtag={`#${productInfo.keyword}`}>
                    <FacebookShare />
                </FacebookShareButton>
            ),
            label: 'Facebook'
        },
        {
            icon: (
                <TwitterShareButton title='Sản phẩm mới' via={window.location.href}>
                    <TwitterIcon />
                </TwitterShareButton>
            ),
            label: 'Twitter'
        }
        // { icon: <SkypeShare />, label: "Skype" },
    ];

    return {
        lightboxProps: {
            toggler: isOpenLightbox,
            sources: imgUrls,
            slide: index + 1
        },
        productOverviewProps: {
            productInfo,
            productImgs,
            intl,
            quantityDemand,
            isOpenPopup,
            imgUrls,
            index,
            setIndex,
            addToCartHandler,
            openShareModal,
            setQuantityDemand,
            mainGroupTypeState,
            displayProductType,
            handleProductTypeChange,
            toggleLightbox,
            favProdId,
            handleDeleteAddress,
            auth,
            checkFavProd,
            totalReviews: allReviewList.length,
            avgRating: calcAvgRating(allReviewList, allReviewList.length)
        },
        productInformationProps: {
            productDetailProps: {
                intl,
                productDetailItems
            },
            readMoreSectionItems,
            intl,
            productId
        },
        addToCartProps: {
            productInfo,
            addToCartHandler,
            quantityDemand,
            mainGroupTypeState,
            displayProductType,
            intl,
            showBuyModalPopup,
            showAddModalPopup,
            isBuyModal,
            closeModalPopup,
            isOpenPopup,
            setQuantityDemand,
            handleProductTypeChange,
            imgUrls,
            toggleLightbox
        },
        feedbackProps: {
            reviewPage,
            setReviewPage,
            reviewHeaderProps: {
                totalReviews: allReviewList.length,
                avgRating: calcAvgRating(allReviewList, allReviewList.length)
            },
            reviewFilterProps: {
                filterCategoryList,
                setFilterCategoryList,
                filterByStarList,
                setFilterByStarList
            },
            reviewList: productReviewsDetails?.collection,
            totalPage: calcTotalPage(productReviewsDetails?.total, productReviewsDetails?.pagesize),
            intl
        },
        recommendProps: {
            intl,
            navigateTo,
            recommendList: productDetails.recommendDetails,
            currentRecommendList,
            setCurrentRecommendList,
            paleTitle: true
        },
        flashDealProps: {
            intl,
            navigateTo,
            flashDealList: productDetails.hotDealDetails,
            paleTitle: true
        },
        shareModalProps: {
            open: isOpenShareModal,
            doClose: closeShareModal,
            intl,
            socialLinks
        }
    };
}
